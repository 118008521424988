import apiClient from './apiClient'

export const getLawyers = async (params) => {
  try {
    const response = await apiClient.get('/sms/lawyers', {
      params
    })
    return response.data
  } catch (error) {
    console.error('Error fetching lawyers:', error)
    throw error
  }
}

export const getLawyersCities = async (params) => {
  try {
    const response = await apiClient.get('/sms/lawyers_cities', {
      params
    })
    return response.data
  } catch (error) {
    console.error('Error fetching cities:', error)
    throw error
  }
}

export const getMessageTemplates = async () => {
  try {
    const response = await apiClient.get('/sms/message_templates')
    return response.data
  } catch (error) {
    console.error('Error fetching templates:', error)
    throw error
  }
}

export const sendMessageSMS = async (formData) => {
  try {
    const response = await apiClient.post('/sms/send_message', formData)
    return response.data
  } catch (error) {
    console.error('Error send message:', error)
    throw error
  }
}

export const cancelSendingProcess = async (formData) => {
  try {
    const response = await apiClient.post('/sms/cancel_sending', formData)
    return response.data
  } catch (error) {
    console.error('Error cancel sending messages:', error)
    throw error
  }
}

export const getSentMessages = async (params) => {
  try {
    const response = await apiClient.get('/sms/sent_messages', {
      params
    })
    return response.data
  } catch (error) {
    console.error('Error fetching templates:', error)
    throw error
  }
}
