<template>
  <div class="main__container">
    <div class="flex flex-col j-btw g-16">
      <div class="flex g-16 a-center">
        <h1>Message Statuses</h1>
      </div>
      <Breadcrumbs />
    </div>
    <div class="clients__container">
      <Suspense>
        <template #default>
          <div class="table__container">
            <div class="table__filter">
              <div class="table__search">
                <!-- <div class="table__searchInput">
                                    <input type="text" v-model="searchQuery" @keyup.enter="fetchElements"
                                        :placeholder="$t('companies.searchPlaceholder')">
                                </div> -->
              </div>
              <div class="table__filterForm">
                <form @submit.prevent="applyFilter" @reset="resetFilter">
                  <label>{{ $t('companies.registration') }}</label>
                  <DatePicker v-model:value="dateFrom" :placeholder="$t('companies.dateFromPlaceholder')" type="date"
                    format="YYYY-MM-DD" />
                  <DatePicker v-model:value="dateTo" :placeholder="$t('companies.dateToPlaceholder')" type="date"
                    format="YYYY-MM-DD" />
                  <button type="submit">{{ $t('companies.apply') }}</button>
                  <button type="reset">{{ $t('companies.reset') }}</button>
                </form>
              </div>
            </div>
            <div class="adminTable">
              <div class="adminTable__header">
                <div class="adminTable__col w-35">Message ID</div>
                <div class="adminTable__col w-20">From</div>
                <div class="adminTable__col w-20">To</div>
                <div class="adminTable__col w-15">Status</div>
                <div class="adminTable__col w-20">Sent At</div>
                <div class="adminTable__col w-10">Error</div>
              </div>
              <div v-if="loading" class="adminTable__body">
                <div v-for="n in 10" :key="n" class="adminTable__row shimmer-table-row">
                  <div class="adminTable__col w-35 shimmer shimmer-box"></div>
                  <div class="adminTable__col w-20 shimmer shimmer-box"></div>
                  <div class="adminTable__col w-15 shimmer shimmer-box"></div>
                  <div class="adminTable__col w-20 shimmer shimmer-box"></div>
                  <div class="adminTable__col w-10 shimmer shimmer-box"></div>
                </div>
              </div>
              <div v-else class="adminTable__body">
                <div v-for="message in elements" :key="message.id" class="adminTable__row">
                  <div class="adminTable__col w-35">{{ message.sid }}</div>
                  <div class="adminTable__col w-20">{{ message.from }}</div>
                  <div class="adminTable__col w-20">{{ message.to }}</div>
                  <div class="adminTable__col w-15 caps" :class="message.status">{{ message.status }}</div>
                  <div class="adminTable__col w-20">{{ message.dateSent }}</div>
                  <div class="adminTable__col w-10">{{ message.errorCode }}</div>
                </div>
              </div>
              <div class="adminTable__footer">
                <button @click="loadMore" :disabled="!nextPage || loading">
                  {{ loading ? 'Loading...' : 'Load More' }}
                </button>
              </div>
            </div>
          </div>
        </template>
        <template #fallback>
        </template>
      </Suspense>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getSentMessages } from '@/api'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import Breadcrumbs from '@/components/HeaderBreadcrumbs.vue'
import { format } from 'date-fns'

export default {
  components: {
    DatePicker,
    Breadcrumbs
  },
  data () {
    return {
      elements: [],
      searchQuery: '',
      dateFrom: '',
      dateTo: '',
      pageSize: 200,
      totalElements: 0,
      loading: false,
      sortKey: '',
      sortOrder: 1,
      openMenuId: null,
      isModalOpen: false,
      selectedUserId: null,
      companyId: null,
      company: null,
      currentPage: null,
      nextPage: true
    }
  },
  created () {
    this.loading = true
    this.companyId = this.$route.params.id
  },
  computed: {
    ...mapState(['user']),
    paginationInfo () {
      return `${this.pageSize * (this.currentPage - 1) + 1}-${Math.min(this.pageSize * this.currentPage, this.totalElements)} ${this.$t('companies.table.resultText')} ${this.totalElements}`
    },
    totalPages () {
      return Math.ceil(this.totalElements / this.pageSize)
    }
  },
  methods: {
    async fetchElements () {
      if (!this.nextPage) return // Если нет следующей страницы, выходим

      this.loading = true
      try {
        const params = {
          pageSize: this.pageSize,
          currentPage: this.currentPage // Передаём текущую страницу
        }
        const response = await getSentMessages(params)

        if (response.status === 'Success') {
          this.elements = [...this.elements, ...response.data] // Добавляем новые элементы
          this.currentPage = response.nextPage ? JSON.parse(response.nextPage) : null // Обновляем currentPage
          this.nextPage = !!response.nextPage // Обновляем флаг наличия следующей страницы
        } else {
          console.error(response.message)
        }
      } catch (error) {
        console.error('Error fetching elements:', error)
      } finally {
        this.loading = false
      }
    },
    loadMore () {
      this.fetchElements() // Вызываем загрузку следующей страницы
    },
    formatDate (timestamp) {
      const date = new Date(timestamp * 1000)
      return format(date, 'MM/dd/yyyy HH:mm')
    },
    openModal () {
      this.isModalOpen = true
    },
    closeModal () {
      this.isModalOpen = false
      this.selectedUserId = null
    },
    openMenu (companyId) {
      this.openMenuId = this.openMenuId === companyId ? null : companyId
    },
    editElement (companyId) {
      console.log('Edit user with ID:', companyId)
      // Add your logic for editing a user
      this.openMenuId = null
    },
    addNote (companyId) {
      console.log('Add note for user with ID:', companyId)
      // Add your logic for adding a note
      this.openMenuId = null
    },
    writeMessage (companyId) {
      this.selectedUserId = companyId
      this.openModal()
      this.openMenuId = null
    },
    sortTable (key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1 // Toggle sort order
      } else {
        this.sortKey = key
        this.sortOrder = 1 // Default to ascending
      }
      this.fetchElements()
    },
    applyFilter () {
      this.currentPage = 1
      this.fetchElements()
    },
    resetFilter () {
      this.searchQuery = ''
      this.dateFrom = ''
      this.dateTo = ''
      this.currentPage = 1
      this.fetchElements()
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.fetchElements()
      }
    },
    // nextPage () {
    //   if (this.currentPage < this.totalPages) {
    //     this.currentPage++
    //     this.fetchElements()
    //   }
    // },
    goToPage (page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page
        this.fetchElements()
      }
    },
    generatePageNumbers () {
      const pages = []
      const totalPages = this.totalPages
      const currentPage = this.currentPage

      if (totalPages <= 7) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i)
        }
      } else {
        if (currentPage <= 4) {
          for (let i = 1; i <= 5; i++) {
            pages.push(i)
          }
          pages.push('...')
          pages.push(totalPages)
        } else if (currentPage >= totalPages - 3) {
          pages.push(1)
          pages.push('...')
          for (let i = totalPages - 4; i <= totalPages; i++) {
            pages.push(i)
          }
        } else {
          pages.push(1)
          pages.push('...')
          for (let i = currentPage - 1; i <= currentPage + 1; i++) {
            pages.push(i)
          }
          pages.push('...')
          pages.push(totalPages)
        }
      }

      return pages
    },
    updateBreadcrumbs () {
      const breadcrumbs = [
        { name: 'Send (SMS)', path: '/sms' },
        { name: 'Message Statuses' }
      ]
      this.$store.dispatch('breadcrumbs/updateBreadcrumbs', breadcrumbs)
    }
  },
  mounted () {
    this.fetchElements()
  }
}
</script>

<style scoped>
.mainButton {
  padding: 8px 10px;
  height: 40px;
}

.clients__container {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  overflow: auto;
}

.adminTable__col {
  font-size: 14px;
}

.caps {
  text-transform: uppercase;
}

.undelivered {
  color: tomato;
}
</style>
